import Vue from 'vue'
import Vuex from 'vuex'
import { Directus } from '@directus/sdk';
import { v4 as uuidv4 } from 'uuid';

import infrastructure_network from './modules/infrastructure/network'
import infrastructure_vm from './modules/infrastructure/vm'
import infrastructure_client from './modules/infrastructure/client'
import infrastructure_clientType from './modules/infrastructure/client_type'
import infrastructure_vm_state from './modules/infrastructure/vm_state'
import infrastructure_image from './modules/infrastructure/image'
import company_location from './modules/company/location'
import company_employee from './modules/company/employee'
import general_region from './modules/general/region'
import general_vmSize from './modules/general/vmSize'
import general_task from './modules/general/task'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    infrastructure_network,
    infrastructure_vm,
    infrastructure_client,
    infrastructure_clientType,
    infrastructure_vm_state,
    infrastructure_image,
    company_location,
    company_employee,
    general_region,
    general_vmSize,
    general_task
  },
  state: {
    percentages: {},
    directus: {},
    ws: {},
    wsHandler: {},
    employee: {}
  },
  getters: {
    loadingPercentage: (state) => {
      var values = Object.values(state.percentages)
      var amount = values.length

      if (amount > 0) {
        var sum = values.map((sum => value => sum += value)(0))
        return (sum / amount)
      } else {
        return 0
      }      
    },
    isLoggedIn: (store) => {
      // wenn instanz lokal bereits vorhanden ist --> was wenn auth token abgelaufen?
      if (store.directus.items !== undefined) return true

      // auth token holen
      var authToken = localStorage.getItem('auth_token')

      // wenn auch dieser null ist, wurde der Benutzer nicht authentifiziert
      if (authToken !== null) {
        // check if auth token is still available
        var payloadStr = atob(authToken.split('.')[1])
        var payload = JSON.parse(payloadStr)
        var isValid = (new Date().getTime() < (payload.exp *1000))

        if (isValid && store.directus.items === undefined) {
          const directus = new Directus(process.env.VUE_APP_DIRECTUS_URL)
          directus.auth.mode = 'json'
          store.directus = directus
        }

        return isValid
      } else {
        return false
      }
    }
  },
  actions: {
    setLoadingPercentage ({ commit }, { key, percentage }) {
      commit('saveLoadingPercentage', { key, percentage })
    },
    async login ({ commit, dispatch }, { email, password }) {
      const directus = new Directus(process.env.VUE_APP_DIRECTUS_URL)
      directus.auth.mode = 'json'
      await directus.auth.login({
        email,
        password
      })
      
      
      var rawData = await directus.users.me.read({
        fields: ['employee.*'],
      });

      var mandants = rawData.employee.map(x => x.mandant)
      localStorage.setItem('current_tenant', mandants[0])
      localStorage.setItem('current_employee', rawData.employee[0].id)

      dispatch('initWs')
      commit('saveDirectus', directus)
      commit('saveEmployee', rawData.employee[0])
    },
    initWsIfNeeded ({ dispatch, state }) {
      if (Object.keys(state.directus).length > 0) {
        if (Object.keys(state.ws).length == 0) {
          console.log('creating new WS connection')
          dispatch('initWs')
        }
      }
    },
    initWs ({ commit, dispatch, state }) {
      var ws = new WebSocket(process.env.VUE_APP_WS_URL);
      ws.onopen = () => {
        ws.send(JSON.stringify({
          id: '12345',
          type: 'auth',
          action: 'login',
          data: {
            user: 'marcel',
            token: '1234'
          }
        }));
      };

      ws.onclose = () => {
        setTimeout(() => {
          console.log('reopening ws')
          dispatch('initWs')
        }, 500)
      }

      ws.onmessage = (event) => {
        try {
          var got = JSON.parse(event.data)
          if (got.id) {
            if (Object.keys(state.wsHandler).indexOf(got.id) >= 0) {
              state.wsHandler[got.id](got)
            } else {
              console.log('no handler defined')
            }
          }
        } catch (e) {
          console.log(e)
        }
      }

      commit('saveWs', ws)
    },
    registerWsHandler ({ commit }, { id, handler }) {
      commit('saveWsHandler', { id, handler })
    },
    wsSendAndWait ({ dispatch, state }, { type, action, data}) {
      return new Promise((res) => {
        var id = uuidv4()
        dispatch('registerWsHandler', {
          id,
          handler: res
        })

        state.ws.send(JSON.stringify({
          id,
          type,
          action,
          data
        }))
      })
    }
  },
  mutations: {
    saveLoadingPercentage (store, {key, percentage}) {
      var temp = Object.assign({}, store.percentages)
      if (percentage != -1) {
        Vue.set(temp, key, percentage)
      } else {
        Vue.delete(temp, key)
      }
      store.percentages = temp
    },
    saveDirectus (store, directus) {
      store.directus = directus
    },
    saveWs (store, ws) {
      store.ws = ws
    },
    saveWsHandler (store, { id, handler }) {
      store.wsHandler[id] = handler
    },
    saveEmployee (store, employee) {
      store.employee = employee
    }
  },
  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: process.env.DEBUGGING
})
  
  