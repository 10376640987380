import Empty from '../../views/Empty.vue'

export default {
    path: 'network',
    name: 'home.infrastructure.network',
    redirect: { name: 'home.infrastructure.network.list' },
    component: Empty,
    children: [{
        path: 'list',
        name: 'home.infrastructure.network.list',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Index.vue')
    },{
        path: 'show/:id',
        name: 'home.infrastructure.network.show',
        redirect: { name: 'home.infrastructure.network.show.overview' },
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Show/Index.vue'),
        children: [{
            path: 'overview',
            name: 'home.infrastructure.network.show.overview',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Show/Overview.vue')
        },{
            path: 'clients',
            name: 'home.infrastructure.network.show.clients',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Show/Clients.vue')
        },{
            path: 'settings',
            name: 'home.infrastructure.network.show.settings',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Show/Settings.vue')
        },{
            path: 'delete',
            name: 'home.infrastructure.network.show.delete',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Show/Delete.vue')
        }]
    },{
        path: 'create',
        name: 'home.infrastructure.network.create',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Network/Create.vue')
    }]
}