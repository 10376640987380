var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getImages () {
        var items = await directus.items('vm_image').readByQuery();
        return items.data
    }
}
  