import VmStateService from "../../../services/infrastructure/VmStateService";

export default {
  namespaced: true,
  state: {
    states: []
  },
  getters: {
    getStateDisplay: (state) => (id) => {
        var data = state.states.filter(x => x.id === id)
        if (data.length > 0) {
            return data[0].display_name
        } else {
            return ''
        }
    },
    getStateColor: (state) => (id) => {
        var data = state.states.filter(x => x.id === id)
        if (data.length > 0) {
            return data[0].display_color
        } else {
            return '#000000'
        }
    },
  },
  actions: {
    async loadStates ({ commit, rootState }) {
        VmStateService.setDirectus(rootState.directus)
        var states = await VmStateService.getStates()
        console.log(states)
        commit('saveState', states)
    }
  },
  mutations: {
    saveState (state, states) {
        state.states = states
    }
  }
}