var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getVmSizes () {
        var items = await directus.items('vm_size').readByQuery();
        return items.data
    }
}
  