var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getUserTasks (user) {
        var items = await directus.items('task').readByQuery({
            filter: {
                user: {
                    _eq: user
                },
                read: {
                    _eq: false
                }
            },
            sort: ["-date_created"]
        });
        return items.data
    },
    async updateTask (task) {
        var data = Object.assign({}, task)
        delete data.id
        return await directus.items('task').updateOne(task.id, data)
    }
}
  