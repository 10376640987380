import Vue from "vue";
import ImageService from "../../../services/infrastructure/ImageService";

export default {
  namespaced: true,
  state: {
    images: [],
    emptyImage: {
      id: '',
      name: ''
    }
  },
  getters: {
    imageById: (state) => (id) => {
      var data = state.images.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyImage
      }
    }
  },
  actions: {
    async loadImages ({ rootState, commit }) {
      ImageService.setDirectus(rootState.directus)
      var images = await ImageService.getImages()
      commit('saveImages', images)
    }
  },
  mutations: {
    saveImages (state, images) {
      var temp = [...state.images]
      images.forEach(image => {
        var index = temp.findIndex(x => x.id === image.id)
        if (index >= 0) {
          Vue.set(temp, index, image)
        } else {
          temp.push(image)
        }
      });

      state.images = temp
    }
  }
}