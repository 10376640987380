import Vm from './vm'
import Network from './network'
import Client from './client'

export default {
    path: 'infrastructure',
    name: 'home.infrastructure',
    redirect: { name: 'home.infrastructure.vm' },
    component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Index.vue'),
    children: [
        Vm,
        Network,
        Client,
    {
        path: 'printer',
        name: 'home.infrastructure.printer',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Printer/Index.vue'),
    },{
        path: 'other',
        name: 'home.infrastructure.other',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Other/Index.vue'),
    }]
}