import Vue from "vue";
import EmployeeService from "../../../services/company/EmployeeService";

export default {
  namespaced: true,
  state: {
    employees: [],
    emptyEmployee: {
      name: '',
      id: '',
      employee: '',
      vms: []
    }
  },
  getters: {
    employeeById: (state) => (id) => {
      var data = state.employees.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyEmployee
      }
    }
  },
  actions: {
    async loadTenantEmployees ({ rootState, commit }) {
      EmployeeService.setDirectus(rootState.directus)
      var employees = await EmployeeService.getEmployeesByTenant(localStorage.getItem('current_tenant'))
      commit('saveTenantEmployees', employees)
    },
    async loadEmployee ({ rootState, commit }, id) {
      EmployeeService.setDirectus(rootState.directus)
      var employee = await EmployeeService.getEmployee(id)
      commit('saveTenantEmployees', [ employee ])
    },
    async createEmployee ({ rootState, commit }, employeeData) {
      EmployeeService.setDirectus(rootState.directus)
      employeeData["mandant"] = localStorage.getItem('current_tenant')
      var employee = await EmployeeService.createEmployee(employeeData)
      commit('saveTenantEmployees', [ employee ])
    }
  },
  mutations: {
    saveTenantEmployees (state, employees) {
      var temp = [...state.employees]
      employees.forEach(employee => {
        var index = temp.findIndex(x => x.id === employee.id)
        if (index >= 0) {
          Vue.set(temp, index, employee)
        } else {
          temp.push(employee)
        }
      });

      state.employees = temp
    }
  }
}