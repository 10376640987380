import Empty from '../../views/Empty.vue'

export default {
    path: 'vm',
    name: 'home.infrastructure.vm',
    redirect: { name: 'home.infrastructure.vm.list' },
    component: Empty,
    children: [{
        path: 'list',
        name: 'home.infrastructure.vm.list',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Index.vue')
    }, {
        path: 'create',
        name: 'home.infrastructure.vm.create',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Create.vue')
    }, {
        path: 'show/:id',
        name: 'home.infrastructure.vm.show',
        redirect: { name: 'home.infrastructure.vm.show.overview' },
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Show/Index.vue'),
        children: [{
            path: 'overview',
            name: 'home.infrastructure.vm.show.overview',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Show/Overview.vue')
        },{
            path: 'console',
            name: 'home.infrastructure.vm.show.console',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Show/Console.vue')
        },{
            path: 'clients',
            name: 'home.infrastructure.vm.show.graphs',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Show/Graphs.vue')
        },{
            path: 'settings',
            name: 'home.infrastructure.vm.show.settings',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Show/Settings.vue')
        },{
            path: 'delete',
            name: 'home.infrastructure.vm.show.delete',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Vm/Show/Delete.vue')
        }]
    }]
}