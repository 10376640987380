<template>
  <div style="height: 100%">
    <MainNavigation>
      <MainTab :to="{ name: 'home.infrastructure' }">Infrastruktur</MainTab>
      <MainTab v-if="false" :to="{ name: 'home.ressources' }">Ressourcen</MainTab>
      <MainTab :to="{ name: 'home.company' }">Unternehmen</MainTab>
      <MainTab v-if="false" :to="{ name: 'home.application' }">Applikationen</MainTab>
      <MainTab v-if="false" :to="{ name: 'home.support' }">Support</MainTab>
      <template v-slot:settings>
        {{ employee.firstname }} {{ employee.lastname }}
        <font-awesome-icon style="padding: 0px 40px; font-size: 20px" icon="user" />
      </template>

      <template v-slot:logo>
        <img src="/img/Logo.png" />
      </template>
    </MainNavigation>
    <div class="loading-slider" :style="loadingSliderStyle"></div>
    
    <TaskList :tasks="tasks" />

    <router-view></router-view>
    <div style="position: absolute; bottom: 0px; padding: 20px; right: 0px; color: #aaa">
      Copyright 2022 - RAIT Systems GmbH
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainNavigation from '@/components/General/MainNavigation/MainNavigation.vue'
import TaskList from '@/components/General/Task/TaskList.vue'
import MainTab from '@/components/General/MainNavigation/MainTab.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'Home',
  components: {
    MainNavigation,
    MainTab,
    TaskList
  },
  async beforeRouteEnter (to, from, next) {
    if (!store.getters.isLoggedIn) {
      next({ name: 'login' })
    } else {
      next() 
    }
  },
  async mounted () {
    this.initWsIfNeeded()
    if(window.task_load_interval) {
      clearInterval(window.task_load_interval)
    }

    window.task_load_interval = setInterval(async () => {
      await this.loadTasks()
    }, 5000)

    await this.loadTasks()
  },
  methods: {
    ...mapActions(['initWsIfNeeded']),
    ...mapActions('general_task', ['loadTasks'])
  },
  computed: {
    ...mapState(['directus', 'employee']),
    ...mapState('general_task', ['tasks']),
    ...mapGetters(['loadingPercentage', 'isLoggedIn']),
    ...mapGetters('infrastructure_client', ['clientById']),
    loadingSliderStyle () {
      return {
        width: this.loadingPercentage + '%'
      }
    }
  }
}
</script>
