import Empty from '../../views/Empty.vue'

export default {
    path: 'client',
    name: 'home.infrastructure.client',
    redirect: { name: 'home.infrastructure.client.list' },
    component: Empty,
    children: [{
        path: 'list',
        name: 'home.infrastructure.client.list',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Client/Index.vue')
    }, {
        path: 'create',
        name: 'home.infrastructure.client.create',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Client/Create.vue')
    }, {
        path: 'show/:id',
        name: 'home.infrastructure.client.show',
        redirect: { name: 'home.infrastructure.client.show.overview' },
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Client/Show/Index.vue'),
        children: [{
            path: 'overview',
            name: 'home.infrastructure.client.show.overview',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Client/Show/Overview.vue')
        },{
            path: 'settings',
            name: 'home.infrastructure.client.show.settings',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Client/Show/Settings.vue')
        },{
            path: 'delete',
            name: 'home.infrastructure.client.show.delete',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Infrastructure/Client/Show/Delete.vue')
        }]
    }]
}