import Vue from "vue";
import RegionService from "../../../services/general/RegionService";

export default {
  namespaced: true,
  state: {
    regions: [],
    emptyRegion: {
      id: '',
      name: '',
      location: '',
      icon: ''
    }
  },
  getters: {
    regionById: (state) => (id) => {
      var data = state.regions.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyRegion
      }
    }
  },
  actions: {
    async loadRegions ({ rootState, commit }) {
      RegionService.setDirectus(rootState.directus)
      var regions = await RegionService.getRegions()
      commit('saveRegions', regions)
    }
  },
  mutations: {
    saveRegions (state, regions) {
      var temp = [...state.regions]
      regions.forEach(region => {
        var index = temp.findIndex(x => x.id === region.id)
        if (index >= 0) {
          Vue.set(temp, index, region)
        } else {
          temp.push(region)
        }
      });

      state.regions = temp
    }
  }
}