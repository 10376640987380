import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Index.vue'
//import Empty from '../views/Empty.vue'
import Infrastructure from './infrastructure'
import Company from './company'
import Electron from './electron'

Vue.use(VueRouter)

const isElectron = require("is-electron");

const routes = [
  {
    path: '/',
    redirect: { name: 'home' },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    redirect: { name: 'home.infrastructure' },
    children: [
      Infrastructure,
      Company,
      {
        path: 'ressource',
        name: 'home.ressources',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/Index.vue'),
        redirect: { name: 'home.ressources.settings' },
        children: [
          {
            path: 'settings',
            name: 'home.ressources.settings',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/Settings/Index.vue'),
          },
          {
            path: 'users',
            name: 'home.ressources.user',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/User/Index.vue'),
          },
          {
            path: 'roles',
            name: 'home.ressources.role',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/Role/Index.vue'),
          },
          {
            path: 'permissions',
            name: 'home.ressources.permission',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/Permission/Index.vue'),
          },
          {
            path: 'shares',
            name: 'home.ressources.share',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/Share/Index.vue'),
          },
          {
            path: 'mail',
            name: 'home.ressources.mail',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Ressources/Email/Index.vue'),
          }
        ]
      },
      {
        path: 'application',
        name: 'home.application',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home/Application/Index.vue'),
        redirect: { name: 'home.application.store' },
        children: [
          {
            path: 'store',
            name: 'home.application.store',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Application/Store/Index.vue'),
          }
        ]
      },
      {
        path: 'support',
        name: 'home.support',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home/Support/Index.vue'),
        redirect: { name: 'home.support.tickets' },
        children: [
          {
            path: 'tickets',
            name: 'home.support.tickets',
            component: () => import(/* webpackChunkName: "about" */ '../views/Home/Support/Tickets/Index.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/electron',
    name: 'electron',
    component: () => import(/* webpackChunkName: "about" */ '../views/Electron/Index.vue'),
    redirect: { name: 'electron.clients' },
    children: [
      Electron
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (from.name === null && from.fullPath === '/') {
    if (to.name.indexOf('home') >= 0 && isElectron()) {
      console.log(to)
      console.log(from)
      console.log('redirecting to electron routes')
      return next({ name: 'electron' })
    }
  }
  next()
})

export default router
