var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getClientsByTenant (tenantId) {
        var items = await directus.items('client').readByQuery({
            filter: {
                mandant: {
                    _eq: tenantId
                }
            }
        });

        return items.data
    },
    async getClient (clientId) {
        var item = await directus.items('client').readOne(clientId)
        return item
    },
    async createClient (client) {
        var created = await directus.transport.post('/client/create', client)
        return created.raw
    },
    async updateClient (client) {
        var id = client.id
        delete client.id
        return await directus.items('client').updateOne(id, client)
    },
    async getClientIdByVmId (vmId) {
        var item = await directus.items('client').readByQuery({
            filter: {
                vm: {
                    _eq: vmId
                }
            },
            fields: ['id']
        })
        return item.data[0].id
    },
    async getUserClients () {
        var user = await directus.users.me.read({
            fields: ['employee.id', 'employee.clients'],
        });

        if (user.employee) {
            var employee = user.employee[0]
            console.log(employee)
            if (employee.clients) {
                return employee.clients
            }
        }
        return []
    }
}
  