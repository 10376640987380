var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getLocationsByTenant (tenantId) {
        var items = await directus.items('location').readByQuery({
            filter: {
                mandant: {
                    _eq: tenantId
                }
            }
        });

        return items.data
    },
    async getLocation (locationId) {
        var item = await directus.items('location').readOne(locationId)
        return item
    },
    async getLocationIdByVmId (vmId) {
        var item = await directus.items('location').readByQuery({
            filter: {
                vm: {
                    _eq: vmId
                }
            },
            fields: ['id']
        })
        return item.data[0].id
    }
}
  