var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getVmsByTenant (tenantId) {
        var items = await directus.items('vm').readByQuery({
            filter: {
                mandant: {
                    _eq: tenantId
                },
                vm_type: {
                    _in: [ 'c7bc4033-26b3-412a-bf68-14cb42bfcfe9', '5888a38a-8fa3-471a-beca-590e8f516a11' ]
                }
            }
        });

        return items.data
    },
    async getVm (vmId) {
        var item = await directus.items('vm').readOne(vmId, { fields: ['*', 'vm_size.*'] })
        return item
    },
    async createVm (vm) {
        var created = await directus.transport.post('/vm/create', vm)
        return created.raw
    },
    async updateVm (vm) {
        var data = Object.assign({}, vm)
        delete data.id
        return await directus.items('vm').updateOne(vm.id, data)
    },
    async getVmsState (vms) {
        var states = await directus.items('vm').readByQuery({
            filter: {
                id: {
                    _in: vms
                }
            },
            fields: ['id', 'state']
        })

        return states.data
    }
}
  