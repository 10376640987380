import Vue from 'vue'
import App from './App.vue'
import VueApexCharts from 'vue-apexcharts'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

/* Sentry.init({
  Vue,
  dsn: "https://6a166d4a2d5341bfa051cae075d45a95@glitchtip.rait-systems.de/3",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "thanos.rait.systems", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
}); */

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
