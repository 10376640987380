import Vue from "vue";
import NetworkService from "../../../services/infrastructure/NetworkService";

export default {
  namespaced: true,
  state: {
    networks: [],
    emptyNetwork: {
      name: '',
      id: '',
      network: '',
      vms: []
    }
  },
  getters: {
    networkById: (state, getters, rootState, rootGetters) => (id) => {
      var data = state.networks.filter(x => x.id === id)
      if (data.length > 0) {
        var network = Object.assign({}, data[0])
        network.vms = network.vms.map(x => rootGetters['infrastructure_vm/vmById'](x))
        return network
      } else {
        return state.emptyNetwork
      }
    }
  },
  actions: {
    async loadTenantNetworks ({ rootState, commit }) {
      NetworkService.setDirectus(rootState.directus)
      var networks = await NetworkService.getNetworksByTenant(localStorage.getItem('current_tenant'))
      commit('saveTenantNetworks', {
        networks,
        isFull: true
      })
    },
    async loadNetwork ({ rootState, commit, dispatch }, id) {
      console.log('loading Tenant Network')
      NetworkService.setDirectus(rootState.directus)
      var network = await NetworkService.getNetwork(id)

      for (var vmId of network.vms) {
        await dispatch('infrastructure_vm/loadVm', vmId, { root: true })
      }

      commit('saveTenantNetworks', [ network ])
    },
    async createNetwork ({ rootState, commit }, { name, region, network, dhcpEnabled, internetEnabled }) {
      NetworkService.setDirectus(rootState.directus)

      var networkToCreate = {
        name,
        region,
        network,
        mandant: localStorage.getItem('current_tenant'),
        network_type: 'd5f52cfd-aa51-43a5-a9de-cba648033bc8',
        state: 'd3eb4bd8-9743-442e-b935-ee9f0868964b',
        dhcp_enabled: dhcpEnabled,
        internet_enabled: internetEnabled
      }

      var createdNetwork = await NetworkService.createNetwork(networkToCreate)
      commit('saveTenantNetworks', [ createdNetwork ])
      return createdNetwork
    },
    async updateNetwork ({ rootState, commit }, data) {
      NetworkService.setDirectus(rootState.directus)

      var updatedNet = NetworkService.updateNetwork(data)
      console.log(updatedNet)

      commit('saveTenantNetworks', [ updatedNet ])
      return updatedNet
    }
  },
  mutations: {
    saveTenantNetworks (state, networks) {
      var isFull = false
      if (!Array.isArray(networks)) {
        isFull = networks.isFull
        networks = networks.networks
      }

      var temp = [...state.networks]
      networks.forEach(network => {
        var index = temp.findIndex(x => x.id === network.id)
        if (index >= 0) {
          Vue.set(temp, index, network)
        } else {
          temp.push(network)
        }
      });

      if (isFull) {
        var existingIds = networks.map(x => x.id)
        temp = temp.filter(x => existingIds.indexOf(x.id) >= 0)
      }

      state.networks = temp
    }
  }
}