import Vue from "vue";
import VmService from "../../../services/infrastructure/VmService";

export default {
  namespaced: true,
  state: {
    vms: [],
    emptyVm: {
      name: '',
      vm_type: '',
      id: ''
    },
    vmsToLoad: []
  },
  getters: {
    vmById: (state) => (id) => {
      var data = state.vms.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyVm
      }
    },
    vmIsClient: (state, getters) => (id) => {
      var vm = getters.vmById(id)
      return vm.vm_type === '5888a38a-8fa3-471a-beca-590e8f516a11'
    }
  },
  actions: {
    async loadTenantVms ({ rootState, commit }) {
      VmService.setDirectus(rootState.directus)
      var vms = await VmService.getVmsByTenant(localStorage.getItem('current_tenant'))
      commit('saveTenantVms', {
        vms,
        isFull: true
      })
    },
    async loadVm ({ rootState, commit }, id) {
      VmService.setDirectus(rootState.directus)
      var vm = await VmService.getVm(id)
      commit('saveTenantVms', [ vm ])
    },
    async createVm ({ rootState, commit }, { name, region, vm_size, image, networks }) {
      VmService.setDirectus(rootState.directus)

      var vm = {
        name,
        region,
        vm_size,
        image,
        network: networks,
        mandant: localStorage.getItem('current_tenant'),
        vm_type: 'c7bc4033-26b3-412a-bf68-14cb42bfcfe9'
      }

      var createdVm = await VmService.createVm(vm)
      commit('saveTenantVms', [ createdVm ])
      return createdVm
    },
    async updateVm ({ rootState, commit }, data) {
      VmService.setDirectus(rootState.directus)

      var updatedVm = VmService.updateVm(data)
      console.log(updatedVm)

      commit('saveTenantVms', [ updatedVm ])
      return updatedVm
    },
    async loadVmsState ({ state, rootState, commit }, vms) {
      if (vms === null || vms === undefined) vms = state.vmsToLoad
      
      console.log('loading vms')
      console.log(vms)
      if (vms.length > 0) {
        VmService.setDirectus(rootState.directus)
        var states = await VmService.getVmsState(vms)
        commit('saveVmsState', states)
      }
    }, 
    async addVmsToStateLoad({ commit }, { vms, load }) {
      commit('saveVmsToStateLoad', { vms, load })
    }
  },
  mutations: {
    saveTenantVms (state, vms) {
      var isFull = false
      if (!Array.isArray(vms)) {
        isFull = vms.isFull
        vms = vms.vms
      }

      var temp = [...state.vms]
      vms.forEach(vm => {
        var index = temp.findIndex(x => x.id === vm.id)
        if (index >= 0) {
          Vue.set(temp, index, vm)
        } else {
          temp.push(vm)
        }
      });

      if (isFull) {
        var existingIds = vms.map(x => x.id)
        temp = temp.filter(x => existingIds.indexOf(x.id) >= 0)
      }

      state.vms = temp
    },
    saveVmsState (state, vmsState) {
      var vms = [...state.vms]
      vmsState.forEach(vmState => {
        var index = vms.findIndex(x => x.id === vmState.id)
        if (index >= 0) {
          var temp = Object.assign({}, vms[index])
          temp.state = vmState.state
          vms[index] = temp
        }
      })

      state.vms = vms
    },
    saveVmsToStateLoad(state, { vms, load }) {
      vms.forEach(vmId => {
        var index = state.vmsToLoad.indexOf(vmId)
        if (index >= 0 && !load) {
          state.vmsToLoad.splice(index, 1)
        } else if(index < 0 && load) {
          state.vmsToLoad.push(vmId)
        }
      })
    }
  }
}