var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getEmployeesByTenant (tenantId) {
        var items = await directus.items('employee').readByQuery({
            filter: {
                mandant: {
                    _eq: tenantId
                }
            }
        });

        return items.data
    },
    async getEmployee (employeeId) {
        var item = await directus.items('employee').readOne(employeeId)
        return item
    },
    async getEmployeeIdByVmId (vmId) {
        var item = await directus.items('employee').readByQuery({
            filter: {
                vm: {
                    _eq: vmId
                }
            },
            fields: ['id']
        })
        return item.data[0].id
    },
    async createEmployee (employee) {
        return await directus.items('employee').createOne(employee)
    }
}
  