var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getClientTypes () {
        var items = await directus.items('client_type').readByQuery();
        return items.data
    }
}
  