import Empty from '../../views/Empty.vue'

export default {
    path: 'employee',
    name: 'home.company.employee',
    redirect: { name: 'home.company.employee.list' },
    component: Empty,
    children: [{
        path: 'list',
        name: 'home.company.employee.list',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Employee/Index.vue')
    },{
        path: 'create',
        name: 'home.company.employee.create',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Employee/Create.vue')
    },{
        path: 'show/:id',
        name: 'home.company.employee.show',
        redirect: { name: 'home.company.employee.show.overview' },
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Employee/Show/Index.vue'),
        children: [{
            path: 'overview',
            name: 'home.company.employee.show.overview',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Employee/Show/Overview.vue')
        },{
            path: 'settings',
            name: 'home.company.employee.show.settings',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Employee/Show/Settings.vue')
        },{
            path: 'delete',
            name: 'home.company.employee.show.delete',
            component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Employee/Show/Delete.vue')
        }]
    }]
}