import Vue from "vue";
import ClientService from "../../../services/infrastructure/ClientService";

export default {
  namespaced: true,
  state: {
    clients: [],
    emptyClient: {
      id: ''
    }
  },
  getters: {
    clientById: (state) => (id) => {
      var data = state.clients.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyClient
      }
    }
  },
  actions: {
    async loadTenantClients ({ rootState, commit }) {
      ClientService.setDirectus(rootState.directus)
      var clients = await ClientService.getClientsByTenant(localStorage.getItem('current_tenant'))
      commit('saveTenantClients', {
        clients,
        isFull: true
      })
    },
    async loadClient ({ rootState, commit }, id) {
      ClientService.setDirectus(rootState.directus)
      var client = await ClientService.getClient(id)
      commit('saveTenantClients', [ client ])
    },
    async createClient ({ rootState, commit }, { name, region, vm_size, networks, client_type, employee }) {
      ClientService.setDirectus(rootState.directus)

      var client = {
        name,
        region,
        vm_size,
        network: networks,
        mandant: localStorage.getItem('current_tenant'),
        client_type,
        employee
      }

      var createdClient = await ClientService.createClient(client)
      commit('saveTenantClients', [ createdClient ])
      return createdClient
    },
    async getClientIdByVmId ({ rootState}, id) {
      ClientService.setDirectus(rootState.directus)
      var clientId = await ClientService.getClientIdByVmId(id)
      return clientId
    },
    async getUserClients ({ rootState }) {
      ClientService.setDirectus(rootState.directus)
      var data = await ClientService.getUserClients()
      return data
    },
    async updateClient ({ rootState, commit }, clientData) {
      ClientService.setDirectus(rootState.directus)
      var client = await ClientService.updateClient(clientData)
      commit('saveTenantClients', [ client ])
    } 
  },
  mutations: {
    saveTenantClients (state, clients) {
      var isFull = false
      if (!Array.isArray(clients)) {
        isFull = clients.isFull
        clients = clients.clients
      }

      var temp = [...state.clients]
      clients.forEach(client => {
        var index = temp.findIndex(x => x.id === client.id)
        if (index >= 0) {
          Vue.set(temp, index, client)
        } else {
          temp.push(client)
        }
      });

      if (isFull) {
        var existingIds = clients.map(x => x.id)
        temp = temp.filter(x => existingIds.indexOf(x.id) >= 0)
      }

      state.clients = temp
    }
  }
}