var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    
    async getStates () {
        var states = await directus.items('vm_state').readByQuery({
            fields: ['id', 'display_name', 'display_color']
        })
        return states.data
    }
}
  