<template>
  <div class="main-nav">
    <div class="main-nav-logo">
      <slot name="logo"></slot>
    </div>
    <div class="main-nav-tabs">
      <slot></slot>
    </div>
    <div class="main-nav-settings">
      <slot name="settings"></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>