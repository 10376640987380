import Vue from "vue";
import LocationService from "../../../services/company/LocationService";

export default {
  namespaced: true,
  state: {
    locations: [],
    emptyLocation: {
      name: '',
      id: '',
      location: '',
      vms: []
    }
  },
  getters: {
    locationById: (state) => (id) => {
      var data = state.locations.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyLocation
      }
    }
  },
  actions: {
    async loadTenantLocations ({ rootState, commit }) {
      LocationService.setDirectus(rootState.directus)
      var locations = await LocationService.getLocationsByTenant(localStorage.getItem('current_tenant'))
      commit('saveTenantLocations', locations)
    },
    async loadLocation ({ rootState, commit }, id) {
      LocationService.setDirectus(rootState.directus)
      var location = await LocationService.getLocation(id)
      commit('saveTenantLocations', [ location ])
    }
  },
  mutations: {
    saveTenantLocations (state, locations) {
      var temp = [...state.locations]
      locations.forEach(location => {
        var index = temp.findIndex(x => x.id === location.id)
        if (index >= 0) {
          Vue.set(temp, index, location)
        } else {
          temp.push(location)
        }
      });

      state.locations = temp
    }
  }
}