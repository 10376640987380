<template>
  <div class="task-list-container">
    <div class="task-list-item" v-for="task in tasks" :key="task.id">
      <div class="task-list-text">
        {{ task.progress }}% - {{ task.title }} <br />
        {{ task.msg }}
      </div>
      <div class="task-list-dismiss" @click="taskReadClicked(task)">
        X
      </div>
      <div class="task-progress" :style="progressStyle(task)"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    tasks: Array
  },
  computed: {
    progressStyle () {
      return (task) => {
        return {
          width: task.progress + '%'
        }
      }
    }
  },
  methods: {
    ...mapActions('general_task', ['updateTask']),
    ...mapActions(['setLoadingPercentage']),
    async taskReadClicked (task) {
      this.setLoadingPercentage({ key: 'general_update_task_read', percentage: 30 })
      
      await this.updateTask({
        id: task.id,
        read: true
      })

      this.setLoadingPercentage({ key: 'general_update_task_read', percentage: 100 })
      this.setLoadingPercentage({ key: 'general_update_task_read', percentage: -1 })
    }
  }
}
</script>

<style>
.task-list-container {
  position: absolute;
  right: 30px;
  top: 70px;
  text-align: left;
}

.task-list-item {
  margin-top: 10px;
  background: white;
  border-radius: 10px;
  border: 1px solid #3f88c5;
  overflow: hidden;
  position: relative;
  min-width: 15vw;
}

.task-list-text {
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
}

.task-list-dismiss {
  padding: 0px 10px;
  padding-top: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
}

.task-list-dismiss:hover {
  cursor: pointer;
}

.task-progress {
  margin-top: 10px;
  height: 2px;
  background: #3f88c5;
  transition: width 0.1s;
}
</style>