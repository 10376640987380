var directus = null

export default {
    async setDirectus (input) {
        directus = input
    },
    async getNetworksByTenant (tenantId) {
        var items = await directus.items('network').readByQuery({
            filter: {
                mandant: {
                    _eq: tenantId
                }
            }
        });

        return items.data
    },
    async getNetwork (networkId) {
        var item = await directus.items('network').readOne(networkId, { fields: ['name', 'network', 'id', 'vms.vm_id'] })
        item.vms = item.vms.map(x => x.vm_id)
        return item
    },
    async createNetwork (network) {
        return await directus.items('network').createOne(network)
    },
    async updateNetwork (network) {
        var data = Object.assign({}, network)
        delete data.id
        return await directus.items('network').updateOne(network.id, data)
    }
}
  