import Vue from "vue";
import TaskService from "../../../services/general/TaskService";

export default {
  namespaced: true,
  state: {
    tasks: [],
  },
  actions: {
    async loadTasks ({ rootState, commit }) {
      TaskService.setDirectus(rootState.directus)
      //ToDo: Change ID to current user
      var tasks = await TaskService.getUserTasks('6c5c3740-b08a-4f8c-8c27-708ba66ddc5c')
      commit('saveTasks', {
        tasks,
        isFull: true
      })
    },
    async updateTask ({ rootState, commit, dispatch }, taskData) {
      TaskService.setDirectus(rootState.directus)
      var task = await TaskService.updateTask(taskData)
      commit('saveTasks', [ task ])
      await dispatch('loadTasks')
    }
  },
  mutations: {
    saveTasks (state, tasks) {
      var isFull = false
      if (!Array.isArray(tasks)) {
        isFull = tasks.isFull
        tasks = tasks.tasks
      }

      var temp = [...state.tasks]
      tasks.forEach(task => {
        var index = temp.findIndex(x => x.id === task.id)
        if (index >= 0) {
          Vue.set(temp, index, task)
        } else {
          temp.push(task)
        }
      });

      if (isFull) {
        var existingIds = tasks.map(x => x.id)
        temp = temp.filter(x => existingIds.indexOf(x.id) >= 0)
      }

      state.tasks = temp
    }
  }
}