import Vue from "vue";
import VmSizeService from "../../../services/general/VmSizeService";

export default {
  namespaced: true,
  state: {
    vmSizes: [],
    emptyVmSizes: {
      id: '',
      name: '',
      cpu_cores: 0,
      ram_size: 0
    }
  },
  getters: {
    vmSizeById: (state) => (id) => {
      var data = state.vmSizes.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyVmSizes
      }
    }
  },
  actions: {
    async loadVmSizes ({ rootState, commit }) {
      VmSizeService.setDirectus(rootState.directus)
      var vmSizes = await VmSizeService.getVmSizes()
      commit('saveVmSizes', vmSizes)
    }
  },
  mutations: {
    saveVmSizes (state, vmSizes) {
      var temp = [...state.vmSizes]
      vmSizes.forEach(vmSize => {
        var index = temp.findIndex(x => x.id === vmSize.id)
        if (index >= 0) {
          Vue.set(temp, index, vmSize)
        } else {
          temp.push(vmSize)
        }
      });

      state.vmSizes = temp
    }
  }
}