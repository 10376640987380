import Location from './location'
import Employee from './employee'
import Settings from './settings'

export default {
    path: 'company',
    name: 'home.company',
    redirect: { name: 'home.company.location' },
    component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Index.vue'),
    children: [
        Location,
        Employee,
        Settings
    ]
}