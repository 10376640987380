import Vue from "vue";
import ClientTypeService from "../../../services/infrastructure/ClientTypeService";

export default {
  namespaced: true,
  state: {
    clientTypes: [],
    emptyClientType: {
      id: '',
      name: '',
      allowed_vm_sizes: [],
      vm_image: ''
    }
  },
  getters: {
    clientTypeById: (state) => (id) => {
      var data = state.clientTypes.filter(x => x.id === id)
      if (data.length > 0) {
        return data[0]
      } else {
        return state.emptyClientType
      }
    }
  },
  actions: {
    async loadClientTypes ({ rootState, commit }) {
      ClientTypeService.setDirectus(rootState.directus)
      var clientTypes = await ClientTypeService.getClientTypes()
      commit('saveClientTypes', clientTypes)
    }
  },
  mutations: {
    saveClientTypes (state, clientTypes) {
      var temp = [...state.clientTypes]
      clientTypes.forEach(clientType => {
        var index = temp.findIndex(x => x.id === clientType.id)
        if (index >= 0) {
          Vue.set(temp, index, clientType)
        } else {
          temp.push(clientType)
        }
      });

      state.clientTypes = temp
    }
  }
}