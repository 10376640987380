export default {
    path: 'settings',
    name: 'home.company.settings',
    redirect: { name: 'home.company.settings.general' },
    component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Settings/Index.vue'),
    children: [{
        path: 'general',
        name: 'home.company.settings.general',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Settings/General.vue')
    },{
        path: 'payment',
        name: 'home.company.settings.payment',
        component: () => import(/* webpackChunkName: "about" */ '../../views/Home/Company/Settings/Payment.vue')
    }]
}